export interface IBasketCalc {
  price(products: any, isDelivery: boolean): number
  priceDiscount(products: any, isDelivery: boolean): number
  count(products: any): number
  discount(products: any): number
  total(products: any): number
}

export default class HeaderBasket implements IBasketCalc {
  // @todo удалить лишние методы
  cost(item: { full_price: number; count: number }) {
    return item.full_price * item.count
  }

  costWithoutDiscount(item: any) {
    let oldPrice = item.full_price

    return Math.trunc(oldPrice) * item.count
  }

  costDiscount(item: { price: number; full_price: number; count: number }) {
    const priceDifference = item.price - item.full_price

    if (priceDifference > 0 || priceDifference < 0) {
      return item.price * item.count
    }
    return item.full_price * item.count
  }

  price(products: any, isDelivery: boolean = true) {
    return products.reduce((acc: any, item: any) => {
      if (item.disabled) return acc

      const { discount, full_price, price } = isDelivery ? item.delivery : item.pickupPrices

      const currentPrice = discount > 0 ? full_price : price
      return item.disabled
        ? (acc += 0)
        : (acc += this.cost({
            full_price: full_price > price && discount === 0 ? full_price : currentPrice,
            count: item.count,
          }))
    }, 0)
  }

  total(products: any) {
    return products.reduce((acc: any, item: any) => {
      return item.disabled ? (acc += 0) : (acc += this.costWithoutDiscount(item))
    }, 0)
  }

  priceDiscount(products: any, isDelivery: boolean = true) {
    return products.reduce((acc: any, item: any) => {
      return item.disabled
        ? (acc += 0)
        : (acc += this.costDiscount({
            price: isDelivery ? item.delivery.price : item.pickupPrices.price,
            full_price: isDelivery ? item.delivery.full_price : item.pickupPrices.full_price,
            count: item.count,
          }))
    }, 0)
  }

  computedDiscount(item: any): number {
    return this.costWithoutDiscount(item) - item.price * item.count
  }

  count(products: any) {
    return products.reduce((acc: any, item: any) => {
      return (acc += item.disabled ? 0 : item.count)
    }, 0)
  }

  discount(products: any) {
    const newProduct = products.map((item: any) => ({
      ...item,
      discount: this.computedDiscount(item),
    }))
    const discount = newProduct.reduce((acc: any, item: any) => {
      return item.disabled ? (acc += 0) : (acc += item.discount)
    }, 0)
    return Math.trunc(discount)
  }
}
